import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GOOGLE_CAPTCHA_SITE_KEY } from '../helpers/baseUrl';
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Login | Smart Genesis </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 20, mb: 5 }}>
              जळगाव शहर महानगरपालिका
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="smartgenesis-login" />
            <Typography variant="body2" sx={{ px: 5, mt: 5, mb: 15, color: 'text.secondary' }}>
              Developed by{' '}
              <a target="_blank" href="https://smartgenesis.io" rel="noreferrer">
                {' '}
                Smart Genesis Team{' '}
              </a>
            </Typography>
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            {/* <Typography variant="h6" gutterBottom sx={{ mb: 5 }}>
              This website is a demo and does not represent a real or operational site
            </Typography> */}
            <Typography variant="h3" gutterBottom sx={{ mb: 5 }}>
              Advertisement Permission QR Code Issuance Panel
            </Typography>
            <Typography variant="h4" gutterBottom sx={{}}>
              Sign in
            </Typography>
            <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA_SITE_KEY}>
              <LoginForm />
            </GoogleReCaptchaProvider>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
