/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { renderToString } from 'react-dom/server';
import dayjs from 'dayjs';
import { Canvg } from 'canvg';
import { Stack, Container, Typography, Grid, Button } from '@mui/material';
import QRCode from 'react-qr-code';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image } from '@react-pdf/renderer';
import JMCLogo from '../../assets/images/jmc-logo.png';
import BannerPhoto from '../../assets/images/banner-photo.png';
// import DMCSIGNv1 from '../../assets/images/new-dmc-sign.png';
import DMCSIGNv2 from '../../assets/images/dmc-sign-2.png';

import { getApplicationById } from '../../Services/apiService';
import TiroDevanagariMarathi from '../../assets/Tiro_Devanagari_Marathi/TiroDevanagariMarathi-Regular.ttf';
import { frontEndUrl } from '../../helpers/baseUrl';

// Register Font
Font.register({
  family: 'TiroDevanagariMarathi',
  src: TiroDevanagariMarathi,
});
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '20px',
    width: '50%',
    fontSize: '12px',
    fontFamily: 'TiroDevanagariMarathi',
    margin: 10,
    justifyContent: 'justify',
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
  },
  signSection: {
    display: 'flex',
    // justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginBottom: '190px',
  },
  image: {
    flexDirection: 'row',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  title: {
    flexDirection: 'row',
    textAlign: 'center',
    fontSize: '16px',
    display: 'flex',
    textDecoration: 'underline',
    fontWeight: 'extrabold',
  },
  borderView: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  border: {
    border: '1px solid #9e9e9e',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: 10,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    borderWidth: 1,
  },
  tableCell: {
    margin: 'auto',
    padding: 5,
  },
  checkbox: {
    fontSize: 12,
    marginRight: 5,
  },
  horizontalLine: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    marginVertical: 10,
  },
  signatureSection: {
    marginTop: 20,
  },
  signatureText: {
    fontSize: 12,
  },
  heading: {
    fontSize: 12,
    fontWeight: 'bold',
    margin: 10,
  },
  content: {
    fontSize: 12,
    marginBottom: 3,
  },
  text: {
    marginBottom: 10,
    fontSize: 12,
  },
});

const svgToDataUri = async (svgString) => {
  try {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      const v = Canvg.fromString(context, svgString.trim());
      await v.render();
      const dataUri = canvas.toDataURL('image/png');
      return dataUri;
    }
    return '';
  } catch (error) {
    console.error('Error occured:', error);
    return '';
  }
};

const LicenseQrCodePdf = () => {
  const { applicationId } = useParams();
  const [svgDataUri, setSvgDataUri] = useState('');

  const [applicationData, setApplicationData] = useState([]);

  useEffect(() => {
    getApplicationDetails();
  }, [applicationId]);

  const getApplicationDetails = async () => {
    const getApplicationRes = await getApplicationById(applicationId);
    if (getApplicationRes._id) {
      setApplicationData(getApplicationRes);
    }
  };

  useEffect(() => {}, [applicationData]);

  const qrCodeRef = React.createRef();

  useEffect(() => {
    async function convertSvgToDataUri() {
      const svg = <QRCode value={`${frontEndUrl}/verify-license/${applicationData._id}`} ref={qrCodeRef} id="QRCode" />;

      const dataUri = await svgToDataUri(renderToString(svg));
      setSvgDataUri(dataUri);
    }

    convertSvgToDataUri();
  }, [applicationData._id, applicationData.permissionId, qrCodeRef]);

  const downloadQRCode = () => {
    const svg = document.getElementById('QRCode');
    console.log('🚀 ~ downloadQRCode ~ canvas:', svg);
    const svgData = new XMLSerializer().serializeToString(svg);
    const pngFile = `data:image/svg+xml;base64,${btoa(svgData)}`;
    const downloadLink = document.createElement('a');
    downloadLink.download = `qrcode-${applicationData._id}`;
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
    // img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <>
      <Helmet>
        <title>जाहिरात फलक (होर्डींग) प्रदर्शीत करणेबाबतचा परवाना पत्र | Smart Genesis </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            जाहिरात फलक (होर्डींग) प्रदर्शीत करणेबाबतचा परवाना पत्र
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          {applicationData._id ? (
            <Grid item size={12}>
              {/* <Typography variant="h6" gutterBottom>
            Download below QR Code
          </Typography> */}

              {applicationData.applicationType === 'JMC' ? (
                <>
                  <PDFViewer style={{ height: '900px', width: '200%' }}>
                    <Document title={applicationData.applicationId}>
                      <Page size="A4" style={styles.page}>
                        <View style={styles.image}>
                          <Image allowDangerousPath src={JMCLogo} style={{ height: '100px', width: '100px' }} />
                          <Text style={styles.title}>जळगाव शहर महानगरपालिका,जळगाव</Text>
                        </View>
                        <View style={styles.section}>
                          <Text style={styles.title}>जाहिरात परवानगी परवाना</Text>
                          <Text style={styles.heading}>परवाना क्रमांक: {applicationData.permissionId}</Text>
                          <Text style={styles.heading}>अर्ज क्रमांक: {applicationData.applicationId}</Text>

                          <Text style={styles.content}>
                            मेसर्स.{' '}
                            <Text style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                              {applicationData.applicant.name}{' '}
                            </Text>{' '}
                            राहणार{' '}
                            <Text style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                              {' '}
                              {applicationData.applicant.address}
                              {' ,'}
                              {applicationData.applicant.city}
                            </Text>{' '}
                            यांना, याद्वारे{' '}
                            {dayjs(applicationData.advertisementDetails.periodFrom).format('DD/MM/YYYY')} पासुन{' '}
                            {dayjs(applicationData.advertisementDetails.periodTo).format('DD/MM/YYYY')} पर्यंतच्या
                            कालावधीसाठी महाराष्ट्र नगरपरिषद / नगरपंचायत (आकाशचिन्हे व जाहिरात प्रदर्शित करण्याचे नियमन व
                            नियंत्रण) मार्गदर्शक तत्वे, 2022 च्यातरतदुींच्या अनपुालन करण्याच्या अधीनतेने, खाली तपशिलावर
                            दिल्याप्रमाणे आकाश-चिन्ह उभारण्यास प्राधिकार दिले आहेत: -
                          </Text>
                          <Text style={styles.heading}>
                            आकाशचिन्हाचे स्वरूप: {applicationData.advertisementDetails.nature}
                          </Text>
                          <Text style={styles.heading}>
                            प्रदर्शनाचे ठिकाण: {applicationData.advertisementDetails.location.fullAddress}
                          </Text>
                          {applicationData.advertisementDetails.locationDetails.length > 0 ? (
                            <View style={styles.section}>
                              <Text>Advertisement Location Details</Text>
                              <View style={styles.table}>
                                {/* Table Header */}
                                <View style={styles.tableRow}>
                                  <View style={styles.tableCell}>
                                    <Text>Location</Text>
                                  </View>
                                  <View style={styles.tableCell}>
                                    <Text>Number of Banners</Text>
                                  </View>
                                  <View style={styles.tableCell}>
                                    <Text>Width</Text>
                                  </View>
                                  <View style={styles.tableCell}>
                                    <Text>Height</Text>
                                  </View>
                                </View>

                                {/* Table Rows */}
                                {applicationData.advertisementDetails.locationDetails.map((locationDetail, index) => (
                                  <View style={styles.tableRow} key={index}>
                                    <View style={styles.tableCell}>
                                      <Text>{locationDetail.location}</Text>
                                    </View>
                                    <View style={styles.tableCell}>
                                      <Text>{locationDetail.numBanners}</Text>
                                    </View>
                                    <View style={styles.tableCell}>
                                      <Text>{locationDetail.width}</Text>
                                    </View>
                                    <View style={styles.tableCell}>
                                      <Text>{locationDetail.height}</Text>
                                    </View>
                                  </View>
                                ))}
                              </View>
                            </View>
                          ) : (
                            <View style={styles.section}>
                              <Text>Advertisement Dimensions</Text>
                              <View style={styles.table}>
                                {/* Table Header */}
                                <View style={styles.tableRow}>
                                  <View style={styles.tableCell}>
                                    <Text>Dimensions</Text>
                                  </View>
                                  <View style={styles.tableCell}>
                                    <Text>In tt/Feet.</Text>
                                  </View>
                                </View>

                                {/* Table Rows */}
                                <View style={styles.tableRow}>
                                  <View style={styles.tableCell}>
                                    <Text>Width: </Text>
                                  </View>
                                  <View style={styles.tableCell}>
                                    <Text>{applicationData.advertisementDetails.dimensionsWidth}</Text>
                                  </View>
                                </View>
                                <View style={styles.tableRow}>
                                  <View style={styles.tableCell}>
                                    <Text>Height: </Text>
                                  </View>
                                  <View style={styles.tableCell}>
                                    <Text>{applicationData.advertisementDetails.dimensionsHeight}</Text>
                                  </View>
                                </View>
                                <View style={styles.tableRow}>
                                  <View style={styles.tableCell}>
                                    <Text>Total Area: </Text>
                                  </View>
                                  <View style={styles.tableCell}>
                                    <Text>{applicationData.advertisementDetails.dimensionsTotalArea}</Text>
                                  </View>
                                </View>
                                <View style={styles.tableRow}>
                                  <View style={styles.tableCell}>
                                    <Text>Length: </Text>
                                  </View>
                                  <View style={styles.tableCell}>
                                    <Text>{applicationData.advertisementDetails.dimensionsLength}</Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                          )}

                          <Text style={styles.content}>
                            अर्जदाराने महानगरपालिकेने विहित केलेल्या दराने चलन क्रमांक.{' '}
                            {applicationData.advertisementDetails.payment.collectedBy} दिनांक:{' '}
                            {dayjs(applicationData.advertisementDetails.payment.paymentDate).format('DD/MM/YYYY')}{' '}
                            द्वारे रुपये (RS.) {applicationData.advertisementDetails.payment.amount} इतके शुल्क प्रदान
                            केले आहे.
                          </Text>
                          <Text style={styles.heading}>दिनांक: {applicationData.updatedDate}</Text>

                          <Text style={styles.heading}>उपायुक्त (महसुल)</Text>
                          <Text style={styles.content}>जळगाव शहर महानगरपालिका, जळगाव</Text>
                        </View>
                      </Page>
                      <Page size="A4" style={styles.page}>
                        <View style={styles.section}>
                          <Text style={styles.heading}>जाहिरातीसाठी QR कोड</Text>
                          {svgDataUri ? <Image src={svgDataUri} /> : ''}
                        </View>
                      </Page>
                    </Document>
                  </PDFViewer>

                  <QRCode value={`${frontEndUrl}/verify-license/${applicationData._id}`} ref={qrCodeRef} id="QRCode" />
                  <Button type="button" onClick={downloadQRCode}>
                    {' '}
                    Download QR Code{' '}
                  </Button>
                </>
              ) : (
                <>
                  <PDFViewer style={{ height: '950px', width: '175%' }}>
                    <Document title={applicationData.applicationId}>
                      <Page size="A4" style={styles.page}>
                        <View style={styles.image}>
                          <Image allowDangerousPath src={BannerPhoto} />
                          {/* <Text style={styles.title}>जळगाव शहर महानगरपालिका,जळगाव</Text> */}
                        </View>
                        <View style={styles.section}>
                          <Text style={styles.title}>जाहिरात फलक (होर्डींग) प्रदर्शीत करणेबाबतचा परवाना पत्र</Text>
                          <Text style={styles.heading}>परवाना क्रमांक: {applicationData.permissionId}</Text>
                          <Text style={styles.heading}>अर्ज क्रमांक: {applicationData.applicationId}</Text>
                          <Text style={styles.content}>प्रति, </Text>
                          <Text style={styles.content}>श्री/सौ. {applicationData.applicant.name} </Text>
                          <Text style={styles.text}>
                            रा. {applicationData.applicant.address}
                            {' ,'}
                            {applicationData.applicant.city}
                          </Text>

                          <Text style={styles.text}>
                            {' '}
                            विषय:- जळगांव शहर महानगरपालिका हद्दीतील {
                              applicationData.advertisementDetails.privateAdress
                            }{' '}
                            या खाजगी जागेवर जाहिरात फलक (होर्डींग) प्रदर्शीत करणेबाबत.{' '}
                          </Text>

                          <Text style={styles.text}>
                            संदर्भ :- 1. आपण जाहिरात बॅनर फलक करिता परवानगी मिळणेकामी केलेला अर्ज. {'\n'} 2. जाहिरात
                            बॅनर परवानगी संदर्भात आपण महानगरपालिकेकडे सादर केलेले प्रतिज्ञापत्र.
                          </Text>
                          <Text style={styles.content}>महोदय,</Text>
                          <Text style={styles.content}>
                            वरील संदर्भीय विषयांन्वये जळगांव शहर महानगरपालिका हद्दीतील{' '}
                            <Text style={{ textDecoration: 'underline', fontWeight: '400' }}>
                              {applicationData.advertisementDetails.privateAdress}{' '}
                            </Text>{' '}
                            या खाजगी जागेवर {applicationData.advertisementDetails.dimensionsWidth} फुट रुंदी x{' '}
                            {applicationData.advertisementDetails.dimensionsHeight} फुट उंची या मापाचे जाहिरात फलक
                            (होर्डींग) प्रदर्शित करणेकामी खालील अटी शर्ती नुसार दि.{' '}
                            {dayjs(applicationData.advertisementDetails.periodFrom).format('DD/MM/YYYY')} ते दि.
                            {dayjs(applicationData.advertisementDetails.periodTo).format('DD/MM/YYYY')} पर्यंत कालावधी
                            करीता आपण इकडेस सादर केलेल्या प्रतिज्ञापत्र/ हमीपत्र याच्या आधारे सदर जाहिरात फलक (होर्डींग)
                            यास परवानगी देण्यात येते आहे. वरील नमुद जागेवरील जाहिरात फलक (होर्डिंग) ची महासभा ठराव
                            क्रमांक 1107 दि 30/05/2023 अन्वये विहित केलेली वार्षीक परवाना फि रु.
                            {applicationData.advertisementDetails.payment.amount}/- मात्र जीएसटी सह परवाना मिळाल्यापासुन
                            आत महानगरपालिकेत जमा करावी. अन्यथा सदरचा परवाना हा रद्द करण्यात येवुन सदर जाहिरात फलक
                            (होर्डिंग) अनधिकृत समजण्यात येईल व आपणास कोणतीही पुर्व सुचना न देता सदर जाहिरात फलक
                            (होर्डिंग) निष्काशीत करण्याबाबतची कार्यवाही करण्यात येईल.
                          </Text>
                        </View>
                        <View style={styles.section}>
                          <Text style={{ textDecoration: 'underline', textAlign: 'center' }}>
                            जाहिरात फलक (होर्डींग) प्रदर्शित करणे बाबतच्या नियम व अटी:-
                          </Text>
                          {/* <Text style={styles.content}>
                            1. संबंधीत स्थानिक पोलिस स्टेशन व शहर वाहतुक नियंत्रण शाखेची आपणास परवानगी घेणे बंधन कारक
                            राहील.
                          </Text> */}
                          <Text style={styles.content}>
                            1. जाहिरात फलक (होर्डींग) यांच्या सुरक्षेतेची जबाबदारी आपली राहील.
                          </Text>
                          <Text style={styles.content}>
                            2. जाहिरात फलक (होर्डींग) उभारतांना तसेच उभारल्यावर नंतर सदर बॅनर/फलका इत्यादी मुळे काही
                            जिवित किंवा वित्तहानी झाल्यास त्याची सर्वस्वी जबाबदारी अर्जदार यांची राहील.
                          </Text>
                          <Text style={styles.content}>
                            3. सदर जाहिरात बॅनर/होर्डिंग/स्ट्रक्चर चे मजबुतीचे लायसन्स प्राप्त संरचना अभियंत्याकडुन
                            स्थैय प्रमाणपत्र (Stability Certificate), अकल्पीत उद्भवणाऱ्या दुर्घटनाच्या दायित्व बाबतचा
                            विमा व इतर आवश्यक कागदपत्र सादर केलेले आहेत. त्यांचे मुदत संपण्या अगोदर नुतनीकरण करण्याची
                            सर्वस्वी जबाबदारी आपली असेल. तसे न केल्यास सदरचा परवाना कोणतीही पुर्वसुचना न देता रद्द
                            करण्यात येईल.
                          </Text>
                          <Text style={styles.content}>
                            4. फलकावर मनपाचा परवाना नंबर व मुदत याबाबतचा QR Code प्रदर्शित करणे बंधन कारक राहील.
                          </Text>
                          <Text style={styles.content}>
                            5. परवानगी दिलेली असेल त्याच ठिकाण जाहिरात फलक (होर्डींग) कापडीफलक/ झेंडे/ पोस्टर्स लावावे.
                          </Text>
                          <Text style={styles.content}>
                            6. जाहिरात फलक (होर्डींग) वर आक्षेपार्ह मजकुर टाकता येणार नाही.
                          </Text>
                          <Text style={styles.content}>
                            7. महाराष्ट्र महानगरपालिका [आकाशचिन्हे(स्काय-साईन) व जाहिरात प्रदर्शित करण्याचे नियमन आणि
                            नियंत्रण], 2022 मधील नियम क्र.17 मधील (नऊ) मधील निर्देशाप्रमाणे आवश्यकतेनुसार पोलिस वाहतुक
                            विभागाची नाहरकत सादर करावी.
                          </Text>
                          <Text style={styles.content}>
                            8. कायदा व सुव्यवस्था वाहतुक किंवा तक्रार अर्ज आल्यास त्याअर्जात तथ्य आढळल्यास सदर परवानगी
                            केव्हाही रद्द करणेचा अधिकार मनपाचा असेल त्यास कोणताही परतावा मिळणार नाही. तसेच आपणास हरकत
                            घेता येणार नाही.
                          </Text>
                          <Text style={styles.content}>
                            {' '}
                            9. जाहिरात फलक (होर्डींग) इत्यादी प्रदर्शित करतांना, सदर जाहिरात फलक (होर्डींग) मुळे वाहन
                            चालक व पादचारी यांच्या दृष्टीस (आयव्हीजनला) बाधा होणार नाही या प्रमाणे लावण्या/ प्रदर्शित
                            करण्यात यावे.
                          </Text>
                          <Text style={styles.content}>
                            10. जाहिरात फलक (होर्डिंग) यास दिलेल्या मुदत कालवाधीच्या 03 महिने अगोदर नुतनिकरणाकरीता विहित
                            नमुन्यात व आवश्यक कागदत्रांसह अर्ज सादर करावा. तसेच मुदत संपल्यानंतर 15 दिवसाच्या आत
                            आपल्याकडुन परिपुर्ण अर्ज व कागदपत्र सादर न केल्यास सदरचे जाहिरात फलक (होर्डिंग) अनधिकृत
                            समजण्यात येवून त्यावर नियमानुसार निष्काशनाची कारवाई करण्यात येईल व त्यास येणारा खर्च
                            आपणाकडून वसुल करण्यात येईल.
                          </Text>
                          <Text style={styles.content} break>
                            11. भविष्यात सदर जाहिरात बॅनर/होर्डिंग/स्ट्रक्चर मुळे नैसर्गीक आपत्ती, वादळ, मुसळधार पर्जन्य
                            किंवा अन्य कोणत्याही प्रकारे काही एक जिवितहानी अथवा वित्तहानी झाल्यास त्याबाबतची सर्वस्वी
                            जबाबदारी ही संबंधीत जाहिरातदाराची राहिल, त्याबाबत जळगांव शहर महानगरपालिकेचा काही एक संबंध
                            राहणार नाही.
                          </Text>
                          <Text style={styles.content}>
                            {' '}
                            12. जळगाव शहर महानगरपालिकेने वेळोवेळी सुचवलेल्या जनहितार्थ तसेच लोकजनजागृती, सामाजिक संदेश,
                            शासकीय उपाययोजनांच्या संदर्भातील जाहिराती आपणास विनामुल्य , बिनशर्त प्रदर्शीत करेल.
                          </Text>
                          <Text style={styles.content}>
                            13. सदर जाहिरात बॅनर/होर्डिंग/स्ट्रक्चर मुळे परिसराचे किंवा संबंधीत मालमत्तेचे विदृपीकरण
                            होणार नाही याची मी सदैव दक्षता घेईल.
                          </Text>
                          <Text style={styles.content}>
                            14. सदर जाहिरात बॅनर/होर्डिंग/स्ट्रक्चर ची वेळो वेळी मजबुती करणे व त्याची निगा राखणे यांची
                            सर्वस्वी जबाबदारी आपली व आपल्या एजन्सीची असुन, तसेच वारा-वादळ, मुसळधार पर्जन्य इत्यादी
                            स्थिंतीमध्ये वाऱ्यास अथवा पर्जन्यास अडथळा निर्माण होऊन अपघात सदृश्य स्थिती निर्माण होईल अशा
                            परिस्थितीत आपणास व आपल्या एजन्सी कोणत्याही होर्डिंग/स्ट्रक्चरवर जाहिरात प्रदर्शीत करणार
                            नाही.
                          </Text>
                          <Text style={styles.content}>
                            15. जाहिरात फलक (होर्डींग) ची मुदत संपल्यातर स्वताहुन काढावे अन्यथा कायदेशीर कार्यवाई
                            करण्यात येईल.
                          </Text>
                          <Text style={styles.content}>
                            {' '}
                            16. मा.उच्च न्यायालय मुंबई यांनी जनहित याचिका क्रमांक 155/2011 मध्ये दिलेल्या निर्देशांचे
                            पालन करणे आपणावर बंधनकारक राहील.
                          </Text>
                          <Text style={styles.content}>
                            17. महाराष्ट्र मालमत्तेच्या विरुपणास प्रतिबंध करण्याकरीता चे अधिनियम 1995 व महाराष्ट्र
                            महानगरपालिका [आकाशचिन्हे(स्काय-साईन) व जाहिरात प्रदर्शित करण्याचे नियमन आणि नियंत्रण], 2022
                            मधील तरतुदी व नियमावली मध्ये दिलेल्या निर्देशांचे पालन करणे आपणावर बंधनकारक राहील.
                          </Text>
                          <Text style={styles.content}>
                            18. जाहिरात बॅनर/होर्डिंग/स्ट्रक्चर बाबत शासन तसेच जळगाव शहर महानगरपालिका वेळोवेळी ज्या अटी
                            शर्ती, नियमावली विहित करेल त्या आपणास व आपल्या एजन्सीला विना शर्त मान्य राहतील.
                          </Text>
                          <Text style={styles.content}>
                            19.यापुर्वी आपल्या जाहिरात एजन्सी कडे थकीत व विवादित असलेल्या जाहिरात कर/जाहिरात परवाना
                            शुल्क बाबत मा. न्यायालयात सुरु असलेल्या याचिकेत जो काही अंतीम निर्णय पारित होईल त्या
                            अनुषंगाने थकीत जाहिरात कर/जाहिरात परवाना शुल्कचा महानगरपालिकेत भरणा करणे आपणावर बंधनकारक
                            राहील.
                          </Text>
                          <Text style={styles.text}>
                            20. जळगांव शहर महानगरपालिका हद्दीतील जाहिरात एजन्सीमार्फत मा. न्यायालयात दाखल याचिकेतील
                            अंतिम निकालाच्या अधिन राहून व संबंधीत जाहिरातदार यांनी सादर केलेले अर्ज, कागदपत्र,
                            प्रतिज्ञापत्र यांच्या अटी शर्तीच्या अधिन राहून सदरचा परवाना देण्यात येत आहे.
                          </Text>

                          <Text style={styles.content}>
                            वरील अटी शर्तींचा भंग झाल्यास शहर विरुपण कायद्यान्वये आपणावर कायदेशीर कार्यवाही करण्यात
                            येईल.
                          </Text>
                          <Text style={styles.content}>
                            वरील 1 ते 20 अटी शर्तीच्या अधिन राहुन आपणास सदरचा परवाना देण्यात येत आहे.
                          </Text>
                        </View>

                        <View style={styles.signSection}>
                          {/* <View style={styles.image}> */}
                          <Image allowDangerousPath src={DMCSIGNv2} style={{ height: '100px', width: '170px' }} />
                          {/* <Text style={styles.title}>जळगाव शहर महानगरपालिका,जळगाव</Text> */}
                          {/* </View> */}
                          <Text style={styles.content}>उपायुक्त (महसुल)</Text>
                          <Text style={styles.content}>जळगाव शहर महानगरपालिका, जळगाव</Text>
                        </View>
                      </Page>
                      <Page size="A4" style={styles.page}>
                        <View style={styles.section}>
                          <Text style={styles.heading}>जाहिरातीसाठी QR कोड</Text>
                          {svgDataUri ? <Image src={svgDataUri} /> : ''}
                        </View>
                      </Page>
                    </Document>
                  </PDFViewer>
                  <QRCode value={`${frontEndUrl}/verify-license/${applicationData._id}`} ref={qrCodeRef} id="QRCode" />
                  <br />
                  <Button type="button" onClick={downloadQRCode}>
                    {' '}
                    Download QR Code{' '}
                  </Button>
                </>
              )}
            </Grid>
          ) : (
            <Typography variant="h6" gutterBottom>
              No data found
            </Typography>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default LicenseQrCodePdf;
