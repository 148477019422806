import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, CircularProgress, Button } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// components
import Iconify from '../../../components/iconify';
import setAuthToken from '../../../utils/setAuthToken';
import { login } from '../../../Services/userService';
import validations from '../../../customValidations/validations';
import validationService from '../../../customValidations/validationService';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState({
    fullName: { errorMsg: '' },
    email: { errorMsg: '' },
    password: { errorMsg: '' },
  });

  const handleInputChange = (name, value) => {
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const customValidations = (name, value) => {
    let errorLength = 0;
    const args = [
      {
        name,
        value,
        validations: validations.loginValidations[name],
      },
    ];

    validationService.validate(args, (validationErr, validationErrMsgs) => {
      if (validationErrMsgs.length > 0) {
        errorLength += 1;
        errorMsg[name].errorMsg = validationErrMsgs[0].validations[0].message;
        setErrorMsg({ ...errorMsg });
      } else {
        errorMsg[name].errorMsg = '';
        setErrorMsg({ ...errorMsg });
      }
    });
    return errorLength;
  };

  const handleLogin = async () => {
    if (!executeRecaptcha) {
      console.log('Recaptcha has not been loaded');
      return;
    }
    const token = await executeRecaptcha('login');

    const errorEmail = customValidations('email', email);
    const errorPassword = customValidations('password', password);

    if (errorEmail !== 1 && errorPassword !== 1) {
      console.log('Login Successful');
    } else {
      console.log('Validation error');
      return;
    }

    setLoading(true);

    const userData = {
      email,
      password,
      recaptchaToken: token,
    };

    // toast.error(`${signUpResponse.data.msg}`);

    const loginResponse = await login(userData);
    // console.log('loginResponse', loginResponse);
    if (loginResponse.status === 200) {
      setAuthToken(loginResponse.data.tokens.access.token);
      localStorage.setItem('token', loginResponse.data.tokens.access.token);
      localStorage.setItem('userId', loginResponse.data.user._id);
      localStorage.setItem('userEmail', loginResponse.data.user.email);
      localStorage.setItem('archivalRole', loginResponse.data.user.role);
      localStorage.setItem('isLogged', 'true');
      setLoading(false);
      navigate('/', { replace: true });
    } else if (loginResponse.status === 401) {
      setLoading(false);
      if (loginResponse.data.error) {
        toast.error(`${loginResponse.data.error}`);
      } else {
        toast.error(`${loginResponse.data.msg}`);
      }
    } else {
      toast.error(`Something went wrong, Please try again later`);
      setLoading(false);
    }
  };

  // const handleClick = () => {
  //   navigate('/dashboard', { replace: true });
  // };

  return (
    <>
      <Stack spacing={3} sx={{ my: 2 }}>
        <TextField
          name="email"
          label="Email address"
          error={errorMsg.email.errorMsg.length > 0}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          helperText={errorMsg.email.errorMsg}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={errorMsg.password.errorMsg.length > 0}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          helperText={errorMsg.password.errorMsg}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

      <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
        {loading ? <CircularProgress size={20} color="inherit" /> : 'Login'}
      </Button>
    </>
  );
}
